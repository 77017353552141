<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>笔记列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
          <el-row>
            <el-col :span="6">
              <el-form-item label="标题">
                <el-input v-model="search.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户昵称">
                <el-input v-model="search.user_nick"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号">
                <el-input v-model="search.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="内容类型">
                <el-select v-model="search.content_type" style="width: 100%" placeholder="请选择类型">
                  <el-option label="全部" value="all"></el-option>
                  <el-option label="照片" value="photo"></el-option>
                  <el-option label="视频" value="video"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间">
                <el-date-picker style="width: 100%" v-model="searchtime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="是否推荐">
                <el-select v-model="search.recommend" style="width: 100%" placeholder="请选择">
                  <el-option label="全部" value="all"></el-option>
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="笔记质量">
                <el-select v-model="search.quality" style="width: 100%" placeholder="请选择">
                  <el-option label="全部" value="all"></el-option>
                  <el-option label="优质" value="s"></el-option>
                  <el-option label="较优质" value="a"></el-option>
                  <el-option label="次优质" value="b"></el-option>
                  <el-option label="合格" value="c"></el-option>
                  <el-option label="需整改" value="d"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="排序">
                <el-select v-model="search.orderby" style="width: 100%" placeholder="请选择排序字段">
                  <el-option label="默认" value=""></el-option>

                  <el-option label="真实访问量↓" value="see_sum"></el-option>
                  <el-option label="总访问量↓" value="total_see_sum"></el-option>
                  <el-option v-if="!is_empty(statisticsTime)" label="统计·访问量↓" value="analysis_see_sum"></el-option>

                  <el-option label="真实点赞量↓" value="thumbs_sum"></el-option>
                  <el-option label="总点赞量↓" value="total_thumbs_sum"></el-option>
                  <el-option v-if="!is_empty(statisticsTime)" label="统计·点赞量↓" value="analysis_thumbs_sum"></el-option>

                  <el-option label="真实收藏量↓" value="collect_sum"></el-option>
                  <el-option label="总收藏量↓" value="total_collect_sum"></el-option>
                  <el-option v-if="!is_empty(statisticsTime)" label="统计·收藏量↓" value="analysis_collect_sum"></el-option>

                  <el-option label="真实回复数↓" value="reply_sum"></el-option>
                  <el-option v-if="!is_empty(statisticsTime)" label="统计·回复数↓" value="analysis_reply_sum"></el-option>

                  <el-option label="发布时间↓" value="create_time"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统计时间">
                <el-date-picker style="width: 100%" v-model="statisticsTime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                  @change="clearStatisticsTime">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户类型">
                <el-select v-model="search.fictitious" style="width: 100%" placeholder="请选择">
                  <el-option label="全部" value="all"></el-option>
                  <el-option label="虚拟用户" value="1"></el-option>
                  <el-option label="真实用户" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="关联品类">
                <MotionCategorySelect :max="1" :motionCategoryList.sync="search.motion_category_two" :showTag="false"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
              @click="issearch = !issearch">隐藏搜索
            </el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="issearch = !issearch">
              显示搜索
            </el-button>
            <el-button icon="el-icon-plus" size="medium" type="primary" @click="add_page">发布笔记
            </el-button>
            <el-button icon="el-icon-download" size="medium" type="primary" @click="exportNote">导出
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column prop="user_nick" label="用户昵称" width="180"> </el-table-column>
        <el-table-column prop="phone" label="用户手机号" width="180"> </el-table-column>
        <el-table-column prop="title" label="笔记标题" width="180"> </el-table-column>
        <el-table-column prop="content" label="笔记内容" width="180">
          <template slot-scope="scope">
            <el-button size="mini" icon="el-icon-tickets" @click="see_content(scope.row.content)">查看内容
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="photo" label="笔记图片/视频" width="130">
          <template slot-scope="scope">
            <div v-if="!is_empty(scope.row.photo)">
              <el-image v-for="(item, index) in scope.row.photo" :key="index"
                style="width: 22px; height: 22px; margin-right: 4px" :src="item" fit="contain"
                :preview-src-list="scope.row.photo">
              </el-image>
            </div>

            <div v-if="!is_empty(scope.row.video)">
              <el-button size="mini" icon="el-icon-video-camera" @click="see_video(scope.row.video)">查看视频
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="user_nick" label="笔记质量" width="120">
          <template slot-scope="scope">
            <el-popover v-if="scope.row.score_total" placement="right" trigger="hover">
              <div>
                <p>总分：{{ scope.row.score_total }}</p>
                <div style="display: inline-block;vertical-align: top; border-right: 1px solid #999;padding-right:30px">
                  <p>基础分：{{ scope.row.score_basics }}</p>
                  <p>基础分得分详情：</p>
                  <p v-for="(item, index) in scope.row.basics_content" :key="index">
                    {{ item.key }}：{{ item.score }}
                  </p>
                </div>
                <div style="display: inline-block;vertical-align: top;margin-left:30px">
                  <p>数据面得分：{{ scope.row.score_additional }}</p>
                  <p>数据面得分详情：</p>
                  <p>阅读量：{{ scope.row.score_see }}</p>
                  <p>点赞：{{ scope.row.score_thumb }}</p>
                  <p>评论：{{ scope.row.score_reply }}</p>
                  <p>收藏：{{ scope.row.score_collect }}</p>
                  <p>转发：{{ scope.row.score_share }}</p>
                  <p>关注作者：{{ scope.row.score_subscribe }}</p>
                </div>
              </div>
              <el-link style="width: 100%; text-align: center" slot="reference" :underline="false" type="primary">
                <span v-if="scope.row.score_total < 20"> 需整改 </span>
                <span v-else-if="scope.row.score_total >= 20 && scope.row.score_total < 40">
                  合格
                </span>
                <span v-else-if="scope.row.score_total >= 40 && scope.row.score_total < 60">
                  次优质
                </span>
                <span v-else-if="scope.row.score_total >= 60 && scope.row.score_total < 80">
                  较优质
                </span>
                <span v-else> 优质 </span>
              </el-link>
            </el-popover>
            <div v-else>
              <p style="width: 100%; text-align: center">暂无评分</p>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="publish_state" label="标签" min-width="200">
          <template slot-scope="scope">
            <el-tag :key="item.index" v-for="item in scope.row.note_tag" class="form-tag_colour-tag" effect="dark"
              size="mini" :color="item.tag_colour">
              {{ item.tag_name }}
            </el-tag>
          </template>
        </el-table-column> -->
        <el-table-column prop="motion_category_two" label="运动品类" min-width="200">
          <template slot-scope="scope">
            <el-tag :key="item.id" v-for="item in scope.row.motion_category_two" class="form-tag_colour-tag"
              effect="dark" size="mini">
              {{ item.name }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="note_topic_info" label="话题" width="180">
          <template slot-scope="scope">
            <!--                        <span  v-for="(item,index) in scope.row.note_topic_info" :key="index">{{ item.topic }}</span>-->
            <el-tag :key="item.index" v-for="item in scope.row.note_topic_info" class="form-tag_colour-tag" effect="dark"
              size="mini" :color="item.colour">
              {{ item.topic }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="store_info" label="门店" width="180">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.store_info" :key="index">{{
              item.store_name
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="examine_state" label="审批状态" width="180">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.examine_state === 0" type="info">未审核</el-tag>
            <el-tag size="mini" v-if="scope.row.examine_state === 1" type="success">
              展示{{ scope.row.examine_time }}
            </el-tag>
            <el-tag size="mini" v-if="scope.row.examine_state === 2" type="danger">不展示</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="examine_time" label="审核时间" width="180">
        </el-table-column>
        <!-- *****点赞数***** -->
        <el-table-column prop="thumbs_sum" label="真实·点赞数" width="100" />
        <el-table-column prop="optimize_thumbs_sum" label="优化·点赞数" width="100" />
        <el-table-column prop="thumbs_sum" label="点赞数总和" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.thumbs_sum + scope.row.optimize_thumbs_sum }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" prop="analysis_thumbs_sum" label="统计·点赞数">
          <template slot-scope="scope">
            <span>{{ scope.row.analysis_thumbs_sum }}</span>
          </template>
        </el-table-column>
        <!-- **************** -->
        <!-- ********收藏数******** -->
        <el-table-column prop="collect_sum" label="真实·收藏数" width="100" />
        <el-table-column width="100" prop="optimize_collect_sum" label="优化·收藏数" />
        <el-table-column prop="collect_sum" label="收藏数总和" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.collect_sum + scope.row.optimize_collect_sum }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" prop="analysis_collect_sum" label="统计·收藏数">
          <template slot-scope="scope">
            <span>{{ scope.row.analysis_collect_sum }}</span>
          </template>
        </el-table-column>
        <!-- **************** -->
        <!-- ********回复数******** -->
        <el-table-column prop="reply_sum" label="真实·回复数" width="100">
        </el-table-column>
        <el-table-column width="100" prop="analysis_reply_sum" label="统计·回复数">
          <template slot-scope="scope">
            <span>{{ scope.row.analysis_reply_sum }}</span>
          </template>
        </el-table-column>
        <!-- **************** -->
        <!-- ********访问量******** -->
        <el-table-column prop="see_sum" label="真实·访问量" width="100" />
        <el-table-column width="100" prop="optimizesee" label="优化·访问量" />
        <el-table-column prop="see_sum" label="访问量总和" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.see_sum + scope.row.optimizesee }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" prop="analysis_see_sum" label="统计·访问数">
          <template slot-scope="scope">
            <span>{{ scope.row.analysis_see_sum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180">
        </el-table-column>
        <el-table-column prop="create_time" label="虚拟笔记">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.fictitious === 1" type="info">是</el-tag>
            <el-tag size="mini" v-if="scope.row.fictitious === 0" type="success">否</el-tag>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="680">
          <template slot-scope="scope">
            <el-button v-if="is_auth('note.fictitious.getuserlist')" @click="showVirtualModel(scope.row)" type="primary"
              size="mini">查看
            </el-button>

            <el-button v-if="is_auth('note.note.settag')" @click="set_tag(scope.row)" type="primary" size="mini">设置品类
            </el-button>
            <el-button v-if="is_auth('note.note.examine') && scope.row.examine_state === 2"
              @click="operation_tip(scope.row.note_uuid, scope.row.user_nick, '1')" type="primary" size="mini">展示
            </el-button>
            <el-button v-if="is_auth('note.note.examine') && scope.row.examine_state === 1"
              @click="operation_tip(scope.row.note_uuid, scope.row.user_nick, '2')" type="danger" size="mini">不展示
            </el-button>
            <el-button v-if="is_auth('note.note.setwell') && scope.row.well === 0" size="mini" type="primary"
              @click="setwell(scope.row.note_uuid, 1)">加入精选
            </el-button>
            <el-button v-if="is_auth('note.note.setwell') && scope.row.well === 1" size="mini" type="danger"
              @click="setwell(scope.row.note_uuid, 0)">移出精选
            </el-button>
            <el-button v-if="is_auth('note.note.setrecommend') && scope.row.recommend === 0" size="mini" type="primary"
              @click="setrecommend(scope.row.note_uuid, 1)">加入推荐
            </el-button>
            <el-button v-if="is_auth('note.note.setrecommend') && scope.row.recommend === 1" size="mini" type="danger"
              @click="setrecommend(scope.row.note_uuid, 0)">移出推荐
            </el-button>
            <el-button v-if="is_auth('note.note.optimizesee')" size="mini" type="primary" @click="visits(scope.row)">
              数据优化
            </el-button>
            <el-button v-if="is_auth('note.note.optimizethumb')" size="mini" type="primary"
              @click="thumbs_optimize(scope.row)">点赞优化
            </el-button>
            <el-button v-if="is_auth('note.fictitious.addnote') && scope.row.fictitious === 1" size="mini" type="primary"
              @click="goToEdit(scope.row.note_uuid)">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>
      <!--数据优化-->
      <el-dialog v-if="is_auth('note.note.optimizesee')" title="数据优化" :visible.sync="visitsVisible" width="40%"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <el-row>
          <el-form size="medium" ref="form" label-width="120px">
            <el-col :span="24">
              <el-form-item label="用户昵称">
                <span class="form-span-text">{{ info.user_nick }}</span>
              </el-form-item>
              <el-form-item label="笔记标题">
                <span class="form-span-text">{{ info.title }}</span>
              </el-form-item>
              <el-form-item label="当前点赞">
                <span class="form-span-text">{{ info.thumbs_sum }}</span>
              </el-form-item>
              <el-form-item label="当前收藏">
                <span class="form-span-text">{{ info.collect_sum }}</span>
              </el-form-item>
              <el-form-item label="当前访问量">
                <span class="form-span-text">{{ info.see_sum }}</span>
              </el-form-item>
              <el-form-item label="优化点赞量">
                <el-input
                  onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                  type="number" v-model="info.optimize_thumbs_sum"></el-input>
              </el-form-item>
              <el-form-item label="优化收藏量">
                <el-input
                  onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                  type="number" v-model="info.optimize_collect_sum"></el-input>
              </el-form-item>
              <el-form-item label="优化访问量">
                <el-input
                  onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                  type="number" v-model="info.optimizesee"></el-input>
              </el-form-item>
              <el-form-item v-if="info.recommend === 1" label="排序">
                <el-input
                  onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                  type="number" v-model="info.orderby"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
          <el-button size="medium" type="primary" @click="save">确 定 </el-button>
          <el-button size="medium" @click="visitsVisible = false">取 消</el-button>
        </el-row>
      </el-dialog>
      <!--笔记视频-->
      <el-dialog title="视频" :visible.sync="see_video_page" width="40%" :close-on-press-escape="false"
        :close-on-click-modal="false">
        <video v-if="see_video_page" width="100%" controls>
          <source :src="this.see_video_src" type="video/mp4" />
          您的浏览器不支持Video标签。
        </video>
      </el-dialog>
      <!--笔记内容-->
      <el-dialog title="笔记内容" :visible.sync="see_content_page" width="40%" :close-on-press-escape="false"
        :close-on-click-modal="false">
        {{ see_content_content }}
      </el-dialog>
      <!--设置品类-->
      <el-dialog v-if="is_auth('note.note.settag')" title="设置品类" :visible.sync="settagPage" width="50%"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <el-row>
          <el-form size="medium" ref="form" label-width="120px">
            <el-col :span="24">
              <el-form-item label="笔记标题">
                <span class="form-span-text">{{ tagdata.title }}</span>
              </el-form-item>
              <el-form-item label="运动品类">
                  <MotionCategorySelect :max="1" :motionCategoryList.sync="tagdata.motion_category_two"/>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
          <el-button v-if="is_auth('note.note.settag')" size="medium" type="primary" @click="issavetag">确 定
          </el-button>
          <el-button size="medium" @click="settagPage = false">取 消</el-button>
        </el-row>
      </el-dialog>
    </div>
    <virtual-model :is-show="isVirtualModelShow" @onClose="closeVirtualModel" @chooseUser="chooseUser" />
  </div>
</template>
<script>
import VirtualModel from "./components/VirtualModel.vue";
import MotionCategorySelect from '@/components/MotionCategorySelect'
export default {
  components: { VirtualModel,MotionCategorySelect },
  data() {
    return {
      page_name: "笔记列表",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      searchtime: null, // 时间筛选条件
      statisticsTime: null, // 统计时间
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容

      visitsVisible: false,
      // 视频查看
      see_video_page: false,
      see_video_src: "",
      // 笔记内容查看
      see_content_page: false,
      see_content_content: "",
      info: {},

      tag_list: [], // 标签列表
      select_tag: "", // 选中的标签
      settagPage: false,
      tagdata: {
        note_uuid: "",
        motion_category_two: [],
      },
      isStatistics: false,
      isVirtualModelShow: false,
      note_uuid: "",
    };
  },
  // 创建
  created() {
    this.init();
    this.gettag();
  },
  // 安装
  mounted() { },
  methods: {
    // 判断是否为空
    is_empty(auth) {
      return this.Tool.is_empty(auth);
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        coupon_bale_title: "",
        publish_state: "",
        state: "",
        content_type: "all",
        orderby: "",
        quality: "all",
        recommend: "all",
        fictitious: "all",
        motion_category_two: []
      };
      this.searchtime = null;
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "note.note.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      // 判断时间条件
      if (this.searchtime !== null) {
        postdata.starttime = this.searchtime[0];
        postdata.endtime = this.searchtime[1];
      }
      if (this.statisticsTime !== null) {
        postdata.ymd_start = this.statisticsTime[0];
        postdata.ymd_end = this.statisticsTime[1];
        this.isStatistics = true;
      } else {
        this.isStatistics = false;
      }
      postdata.motion_category_two = this.search.motion_category_two[0]?.id||''
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 获取标签
    gettag() {
      let postdata = {
        api_name: "note.note.gettag",
        token: this.Tool.get_l_cache("token"),
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tag_list = json.data;
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 操作提示
    operation_tip(note_uuid = "", user_nick = "", examine = "") {
      let tip = "";
      let options = {};
      // 启用
      if (examine == "1") {
        tip = "展示【" + user_nick + "】的笔记？";
      }
      // 禁用
      if (examine == "2") {
        tip = "不展示【" + user_nick + "】的笔记？";
      }
      // 弹出二次确认
      this.$confirm(tip, "确认信息", options)
        .then(() => {
          this.isoperation(note_uuid, examine);
        })
        .catch(() => { });
    },
    // 操作
    isoperation(note_uuid = "", examine = "") {
      let postdata = {
        api_name: "note.note.examine",
        token: this.Tool.get_l_cache("token"),
        note_uuid,
        examine,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 添加
    add_page() {
      this.$router.push({ path: "/note/add" });
    },
    goToEdit(note_uuid) {
      this.$router.push({ path: "/note/add", query: { note_uuid } });
    },
    visits(row = {}) {
      this.info = row;
      this.visitsVisible = true;
    },
    save() {
      let postdata = {
        api_name: "note.note.optimizesee",
        token: this.Tool.get_l_cache("token"),
      };
      Object.assign(postdata, this.info);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              // this.page = 1
              this.info = {};
              this.visitsVisible = false;
              this.getlist();
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 设置精选
    setwell(note_uuid, well) {
      let postdata = {
        api_name: "note.note.setwell",
        token: this.Tool.get_l_cache("token"),
        note_uuid,
        well,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 设置推荐
    setrecommend(note_uuid, recommend) {
      let postdata = {
        api_name: "note.note.setrecommend",
        token: this.Tool.get_l_cache("token"),
        note_uuid,
        recommend,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 查看视频
    see_video(src) {
      this.see_video_page = true;
      this.see_video_src = src;
    },
    see_content(content) {
      this.see_content_page = true;
      this.see_content_content = content;
    },
    set_tag(data) {
      console.log("data", data);
      this.tagdata = JSON.parse(JSON.stringify(data));

      this.settagPage = true;
    },
    // 添加标签
    add_tag() {
      let select_tag = this.select_tag.split("|");
      let tag = {
        tag_name: select_tag[0],
        tag_colour: select_tag[1],
      };
      if (!this.tag_exist(tag.tag_name)) {
        this.tagdata.note_tag.push(tag);
      }
      this.select_tag = "";
    },
    // 判断标签是否存在
    tag_exist(tag_name) {
      if (this.tagdata.note_tag.length > 0) {
        for (let v of this.tagdata.note_tag) {
          if (tag_name == v.tag_name) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },
    // 删除标签
    tagClose(tag) {
      // console.log('tag', tag);
      // console.log('tag2', this.tagdata.store_tag);
      this.tagdata.note_tag.splice(this.tagdata.note_tag.indexOf(tag), 1);
    },
    // 保存标签
    issavetag() {
      let postdata = {
        api_name: "note.note.setcategory",
        token: this.Tool.get_l_cache("token"),
        note_uuid: this.tagdata.note_uuid,
      };
      postdata.motion_category_two = this.tagdata.motion_category_two[0]?.id
      Object.assign(postdata);

      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        this.getlist(); // 刷新列表(重新搜索)
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.settagPage = false; // 关闭窗口
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 导出笔记
    exportNote() {
      let postdata = {
        api_name: "note.note.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
        export: "yes",
      };
      Object.assign(postdata, this.search);
      // 判断时间条件
      if (this.searchtime !== null) {
        postdata.starttime = this.searchtime[0];
        postdata.endtime = this.searchtime[1];
      }
      if (this.statisticsTime !== null) {
        postdata.ymd_start = this.statisticsTime[0];
        postdata.ymd_end = this.statisticsTime[1];
      }
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        if (res.code === 10005) {
          this.Tool.errormes(res);
        } else {
          location.href = res;
        }
        this.loading = false;
      });
    },
    clearStatisticsTime(e) {
      if (!e) {
        this.search.orderby = "";
      }
    },
    // 展示虚拟用户选择列表
    showVirtualModel(data) {
      const userItem = this.Tool.get_s_cache("virtualUserItem");
      const { note_uuid } = data;
      this.note_uuid = note_uuid;
      if (userItem) {
        this.$router.push({ path: "/note/operate", query: { note_uuid } });
      } else {
        this.isVirtualModelShow = true;
      }
    },
    closeVirtualModel() {
      this.isVirtualModelShow = false;
    },
    chooseUser() {
      const { note_uuid } = this;
      this.$router.push({ path: "/note/operate", query: { note_uuid } });
    },
    // 点赞优化
    thumbs_optimize(item) {
      const { note_uuid } = item;
      this.$prompt("请输入需要优化的点赞数", "点赞优化", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "number",
      })
        .then(({ value }) => {
          this.optimizethumb(note_uuid, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 点赞优化接口
    optimizethumb(note_uuid, quantity) {
      let postdata = {
        api_name: "note.note.optimizethumb",
        token: this.Tool.get_l_cache("token"),
        quantity,
        note_uuid,
      };
      this.Tool.post_data("oss", postdata, (res) => {
        if (res.code === 0) {
          if (res.data.thumb_sum === 0) {
            this.$message({
              message: "优化失败，当前可用虚拟用户数为零",
              type: "error",
              duration: 2000,
            });
          } else {
            if (res.data.thumb_sum < quantity) {
              this.$message({
                message: `当前可用的虚拟用户数少于输入的优化数，已优化${res.data.thumb_sum}`,
                type: "warning",
                duration: 2500,
                onClose: () => {
                  this.getlist(); // 刷新列表(重新搜索)
                },
              });
            } else {
              this.$message({
                message: "优化成功",
                type: "success",
                duration: this.env.message_duration,
                onClose: () => {
                  this.getlist(); // 刷新列表(重新搜索)
                },
              });
            }
          }
        } else {
          this.Tool.errormes(res);
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
.el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.form-tag_colour-div {
  min-height: 200px;
}
</style>
